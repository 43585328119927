$theme-colors: (
  'primary': #EBF2FE,
  'secondary': #050227
);

$body-bg: #EBF2FE;
$body-color: #400c4b;


@import '~bootstrap/scss/bootstrap.scss';

// 3c0e4b e5e5e5