.accordion-body{
    background-color: rgb(255, 255, 255);
}

.accordion-button:not(.collapsed) {
    color: #ffffff;
    background: linear-gradient(to bottom, #050227, #77a4fe);
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

.accordion-button {
    color: #ffffff;
    background: linear-gradient(to right, #050227, #77a4fe);
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}
