@media (min-width: 360px) {

  /* Primeiro quadro */

  .bbb{
    background-color: #fff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    width: 95%;
    margin: auto;
    margin-bottom: 2vh;
    height: 77vh;
  }

  .ccc{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    margin: 2vh 0 2vh 0;
  }

  .image{
    padding-top: 2vh;
    width: 350px;
    align-items: center;
    justify-content: center;
    /*background-color: aqua;*/
  }


  .image img{
    width: 40vh;    
  }


  .bb{
    padding: 2vh 0 0 0;
    margin:auto;
    width: max-content;
    /*background-color: blueviolet;*/
  }

  .the-content h1 {
    font-size: 3vh;
  }
  
  .the-content p {
    margin-top: 2vh;
    color: #9e9e9e;
    font-size: 1.0em;
  }

}




@media (min-width: 450px) {



  /* Primeiro quadro */

  .bbb{
    background-color: #fff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    width: 95%;
    margin: auto;
    margin-bottom: 2vh;
    height: 85vh;
  }

  .ccc{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    margin: 2vh 0 2vh 0;
  }

  .image{
    padding-top: 2vh;
    width: 400px;
    align-items: center;
    justify-content: center;
    /*background-color: aqua;*/
  }

  .image img{
    width: 40vh;    
  }
  
  .the-content{
    width: 400px;
    
    /*background-color: blueviolet;*/
  }

  .bb{
    padding: 4vh 0 0 0;
    margin:auto;
    width: max-content;
    /*background-color: blueviolet;*/
  }

  .the-content h1 {
    font-size: 3vh;
  }
  
  .the-content p {
    margin-top: 1vh;
    color: #9e9e9e;
    font-size: 1.1em;
  }

  
  /* Quadros restantes */

}




@media (min-width: 1200px) {

   /* Primeiro quadro */

  .bbb{
    background-color: #fff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    width: 95%;
    margin: auto;
    margin-bottom: 5vh;
    height: 80vh;
  }
  
  .ccc{
    margin: 2vh 0 2vh 0;
    display: block;
  }

  .image{
    float: right;
    width: 50%;
    align-items: center;
    justify-content: center;
    /*background-color: rgb(0, 255, 106);*/
    margin-top: 3vh;
    order: 2;
  }

  .image img{
    width: 60vh;    
  }
  

  .the-content{
    width: 50%;
    padding: 10vh 0 0 0;
    /*background-color: blueviolet;*/
    text-align:left;
    order: 1;
  }

  .bb{
    margin: 10vh 0 0 20vh;
    width: max-content;
  }

  .the-content h1 {
    font-size: 5vh;
    padding-left: 6vh;
  }
  
  .the-content p {
    /*color: #9e9e9e;*/
    padding-left: 7vh;
    padding-top: 2vh;
    font-size: 1.5em;
    /*background-color: blueviolet;*/
  }


  /* Quadros restantes */
  .home-container {
    padding: 10px;
    font-size: 20px;
    text-align: center;
  }

  .chart{
    width: 400px;
    margin-right: 20vh;

  }

}

@media (min-width: 1920px) {

   /* Primeiro quadro */

  .image{
    float: right;
    width: 50%;
    align-items: center;
    justify-content: center;
    /*background-color: rgb(0, 255, 106);*/
    margin-top: 3vh;
    order: 2;
  }

  .the-content{
    padding: 10vh 0 0 10vh;
    /*background-color: rgb(43, 226, 195);*/
    text-align:left;
  }

  .the-content p {
    /*color: #9e9e9e;*/
    font-size: 1.7em;
  }

  /* Quadros restantes */

  .home-container {
    padding: 10px;
    font-size: 20px;
    text-align: center;
  }
  
  .home-card {
    display: flex;

    border-radius: 20px;
  }
  
  .home-content-container {
  
    align-items: center;
    padding: 10px 10px 60px 10px;
  }
  
  .home-content-container h1 {
    width: 260px;
    font-size: 1em;
    margin-bottom: 15px;
  }
  
  .home-content-container p {
    width: 260px;
    color: #9e9e9e;
    font-size: 1.7em;
    margin-bottom: 30px;
  }
  
  .home-image-container {
    padding: 50px 15px;
  }
  
  .chart{
    width: 600px;
    margin-right: 20vh;

  }


}