.main-nav {
  padding-left: 40px;
  min-height: 70px;
  background: linear-gradient(to right, #230739, #77a4fe)!important;
}

.nav-logo-text {
  color: #fff;
}

.nav-logo-text:hover {
  color: #fff;
}

.main-menu {
  display: flex;
  font-size: 18px;
  width: 480px;
  justify-content: space-between;
}

.main-menu a {
  font-style: normal;
  color: rgba(255, 255, 255, 0.5);
}

.main-menu a.active {
  font-weight: bold;
  color: #fff;
}

.main-menu a:hover {
  color: #fff;
}
