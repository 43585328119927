@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');

* {
  font-family: 'Open Sans', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
  font-weight: 700;
}

a,
a:hover {
  text-decoration: none;
  color: unset;
}

p{
  font-size: 18px;
}

.base-card {
  background-color: #fff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}



