.card-body, .card-footer{
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
}

.card-one{
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
}

.card-multiples{
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
}

.card-multiples-titles{
    font-family: 'Open Sans', sans-serif;
    font-size: 32px;
    font-weight: bold;
}

.card-title{
    font-family: 'Open Sans', sans-serif;
    font-size: 48px;
    font-weight: bold;
}

.whatsapp-button {
    width: 100%;
    background: linear-gradient(to bottom, #77a4fe, #050227);
    color: #fff;
    transition: transform 0.3s ease;
  }
  
  .whatsapp-button:hover {
    color: #fff;
    transform: scale(1.05); 
  }
  

@media (max-width: 1200px) {
.card-title{
    font-family: 'Open Sans', sans-serif;
    font-size: 36px;
    font-weight: bold;
}
}