.form-group input{
    background-color: white;
}

.form-group textarea{
    background-color: white;
}

.send-ok{
    background-color: #adcdfd;
}


.send-error{
    background-color: #230739;
    color: white;
}

 
.my-effect{
    border: 1px inset white !important;
    background: linear-gradient(to right, #230739, #77a4fe)!important;
  }

textarea{
    resize: none;
}

label, small{
    
    text-shadow: 2px 2px #cbe5ec;
   
}